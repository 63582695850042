import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useRef, useEffect } from "react"
import {
  FaEnvelopeOpen,
  FaMapMarkerAlt,
  FaBars,
  FaCaretDown,
} from "react-icons/fa"
import "react-sticky-header/styles.css"
import StickyHeader from "react-sticky-header"

import classNames from "classnames"

import "./css/topbar.css"
import "./css/header.css"

import logo from "../images/logo.jpg"

const TopBar = () => (
  <div className="container">
    <div className="topbar full-container">
      <div className="container bg-yellow">
        <ul className="topbar-menu">
          <li className="mr-6">
            <Link to="/yhteystiedot" title="Yhteystiedot">
              Yhteystiedot
            </Link>
          </li>
        </ul>

        <div className="header-info-col">
          <FaEnvelopeOpen className="inline-block" />{" "}
          <strong>
            <a href="mailto:info@shk.fi" title="info@shk.fi">
              info@shk.fi
            </a>
          </strong>
        </div>
        <div className="header-info-col">
          <FaMapMarkerAlt className="inline-block" />{" "}
          <strong>Matti Halme, Koivurinne 4, 18100 Heinola</strong>
        </div>
      </div>
    </div>
  </div>
)
const menuItemVisibleInitial = {
  Liitto: false,
  Lainsäädäntö: false,
  Kestävä: false,
  Muuta: false,
  Ajankohtaista: false,
}

const Header = ({ siteTitle }) => {
  const [menuItemVisible, toggleMenuItemVisible] = useState(menuItemVisibleInitial)

  const [headerFixed, handleHeaderFixed] = useState(false)
  const [headerHeight, handleHeaderHeight] = useState(0)
  const [menuMobileOpened, handleMenuMobileOpened] = useState(false)
  const headerRef = useRef()

  const toggleMenuItem = nameMenuItemVisible => ({
    onMouseEnter: () =>
      toggleMenuItemVisible({
        ...menuItemVisibleInitial,
        [`${nameMenuItemVisible}`]: !menuItemVisible[nameMenuItemVisible],
      }),
    onMouseLeave: () =>
      toggleMenuItemVisible({
        ...menuItemVisibleInitial,
        [`${nameMenuItemVisible}`]: !menuItemVisible[nameMenuItemVisible],
      }),
  })

  const handleSticky = status => {
    handleHeaderFixed(status)
  }

  useEffect(() => {
    handleHeaderHeight(headerRef.current.offsetHeight)
  }, [])

  return (
    <div className="relative">
      <div style={{ paddingTop: headerFixed ? headerHeight : 0 }}>
        <TopBar />
        <StickyHeader
          onSticky={handleSticky}
          // This is the sticky part of the header.
        >
          <div
            className={classNames("site-header-wrapper", {
              headerFixed,
              container: !headerFixed,
            })}
            ref={headerRef}
            style={{
              margin: !headerFixed && "0 auto",
              padding: !headerFixed && 0,
            }}
          >
            <div className="container bg-white">
              <div className="site-header">
                <div className="site-logo">
                  <Link to="/" title="SHK">
                    <img src={logo} alt="SHK_logo" width="150" />
                  </Link>
                </div>

                <span
                  id="menu-toggle"
                  onClick={() => handleMenuMobileOpened(!menuMobileOpened)}
                >
                  <FaBars className="inline-block" />
                </span>

                <nav
                  className={classNames("main-navigation", {
                    menuMobileOpened,
                  })}
                >
                  <ul className="dd-menu sf-menu">
                    <li>
                      <Link to="/" title="Etusivu">
                        <span className="top-link">Etusivu</span>
                      </Link>
                    </li>
                    <li {...toggleMenuItem("liitto")}>
                      <div style={{ lineHeight: 3 }}>
                        <span className="top-link">Liitto</span> <FaCaretDown />
                      </div>
                      <ul
                        className={classNames("dd-ul", {
                          "dd-ul-opened": menuItemVisible.liitto,
                        })}
                      >
                        <li>
                          <Link to="/saannot" title="Säännöt">
                            Säännöt
                          </Link>
                        </li>
                        <li>
                          <Link to="/liittohallitus" title="Liittohallitus">
                            Liittohallitus
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/jaseneksi-hakeutuminen"
                            title="Jäseneksi hakeutuminen"
                          >
                            Jäseneksi hakeutuminen
                          </Link>
                        </li>
                        <li>
                          <Link to="/tilastot" title="Tilastot">
                            Tilastot
                          </Link>
                        </li>
                        <li>
                          <Link to="/yhteystiedot" title="Yhteystiedot">
                            Yhteystiedot
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/toimintakertomukset-ja-poytakirjat"
                            title="Toimintakertomukset ja pöytäkirjat"
                          >
                            Toimintakertomukset ja pöytäkirjat
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/tietosuojaseloste"
                            title="Tietosuojaseloste"
                          >
                            Tietosuojaseloste
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link to="/saadokset" title="Säädökset">
                        <span className="top-link">Säädökset</span>
                      </Link>
                    </li>
                    <li {...toggleMenuItem("Kestävä")}>
                      <div style={{ lineHeight: 3 }}>
                        <span className="top-link">Kestävä kehitys</span>{" "}
                        <FaCaretDown />
                      </div>
                      <ul
                        className={classNames("dd-ul", {
                          "dd-ul-opened": menuItemVisible.Kestävä,
                        })}
                      >
                        <li>
                          <Link
                            to="/laadun-tunnistamisprosessi"
                            title="Laadun tunnistamisprosessi"
                          >
                            Laadun tunnistamisprosessi
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/arkkujen-ja-uurnien-laatujarjestelma"
                            title="Arkkujen ja uurnien laatujärjestelmä"
                          >
                            Arkkujen ja uurnien laatujärjestelmä
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li {...toggleMenuItem("Muuta")}>
                      <div style={{ lineHeight: 3 }}>
                        <span className="top-link">Muuta</span> <FaCaretDown />
                      </div>
                      <ul
                        className={classNames("dd-ul", {
                          "dd-ul-opened": menuItemVisible.Muuta,
                        })}
                      >
                        <li>
                          <Link
                            to="/hautajaisjarjestelyni"
                            title="Hautajaisjärjestelyni"
                          >
                            Hautajaisjärjestelyni
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/hautausmaa-alan-nimikkeisto"
                            title="Hautausmaa-alan nimikkeistö"
                          >
                            Hautausmaa-alan nimikkeistö
                          </Link>
                        </li>
                        <li>
                          <Link to="/linkit" title="Linkit">
                            Linkit
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/sahkoinen-ilmoittautuminen-koulutuspaiville"
                            title="Sähköinen ilmoittautuminen koulutuspäiville"
                          >
                            Sähköinen ilmoittautuminen koulutuspäiville
                          </Link>
                        </li>{" "}
                        <li>
                          <Link
                            to="/aiemmat-luentomateriaalit"
                            title="Aiemmat luentomateriaalit"
                          >
                            Aiemmat luentomateriaalit
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </StickyHeader>
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
