/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"
import "./css/bootstrap.css"
import "./css/theme.css"

const Layout = ({ children, pathname }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className="site-wrapper">
        <Header siteTitle={data.site.siteMetadata.title} pathname={pathname} />

        <main className="main-content container bg-white pb-6">{children}</main>

        <div className="bg-yellow container ">
          <div className=" text-white flex flex-wrap align-middle  p-10">
            <h3 className="text-white text-2xl m-0">
              Vastaamme mielellämme kysymyksiin ja tiedusteluihin
            </h3>{" "}
            <Link
              to="/yhteystiedot"
              title="Ota yhteyttä"
              className="button button-default button-transparent md:ml-auto"
            >
              Ota yhteyttä
            </Link>
          </div>
        </div>
        <footer className="container pt-5 pb-5 text-sm bg-white">
          © {new Date().getFullYear()},{" "}
          <Link className ="pr-4" to="/">Suomen Hautaustoiminnan keskusliitto Ry</Link>
          <Link to="tietosuojaseloste">Tietosuojaseloste</Link>
        </footer>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
